@tailwind base;
@tailwind components;
/* @tailwind utilities; */

*::-webkit-scrollbar {
  width: 0px;
  height: 5px;
}

*::-webkit-scrollbar-track {
  background-color: #ea5f95;
}

*::-webkit-scrollbar-thumb {
  background: rgb(51 42 41);
  border-radius: 25px;
}

:root {
  --toastify-color-light: #90caf905;
  --toastify-color-dark: #121212;
  --toastify-color-info: #3498db;
  --toastify-color-success: #07bc0c;
  --toastify-color-warning: #f1c40f;
  --toastify-color-error: #e74c3c;
  --toastify-color-transparent: rgba(255, 255, 255, 0.7);

  --toastify-icon-color-info: var(--toastify-color-info);
  --toastify-icon-color-success: var(--toastify-color-success);
  --toastify-icon-color-warning: var(--toastify-color-warning);
  --toastify-icon-color-error: var(--toastify-color-error);

  --toastify-toast-width: 320px;
  --toastify-toast-background: #fff;
  --toastify-toast-min-height: 64px;
  --toastify-toast-max-height: 800px;
  --toastify-font-family: sans-serif;
  --toastify-z-index: 19999;

  --toastify-text-color-light: #e7c184;
  --toastify-text-color-dark: #fff;

  --toastify-text-color-info: #fff;
  --toastify-text-color-success: #fff;
  --toastify-text-color-warning: #fff;
  --toastify-text-color-error: #fff;

  --toastify-spinner-color: #616161;
  --toastify-spinner-color-empty-area: #e0e0e0;

  --toastify-color-progress-light: linear-gradient(
    to right,
    #4cd964,
    #5ac8fa,
    #007aff,
    #34aadc,
    #5856d6,
    #ff2d55
  );
  --toastify-color-progress-dark: #bb86fc;
  --toastify-color-progress-info: var(--toastify-color-info);
  --toastify-color-progress-success: var(--toastify-color-success);
  --toastify-color-progress-warning: var(--toastify-color-warning);
  --toastify-color-progress-error: var(--toastify-color-error);
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

input[type='range'] {
  -webkit-appearance: none;
  appearance: none;
  background: linear-gradient(#b38357, #211616);
  cursor: pointer;
}

input[type='range']::-webkit-slider-thumb {
  -webkit-appearance: none; /* Override default look */
  appearance: none;
  background-color: #211616;
  height: 1rem;
  width: 1rem;
  border: 2px solid #b38357;
}

@font-face {
  font-family: 'Lucidity Condensed Regular';
  src: url('./font.woff2') format('woff2');
  font-display: swap;
}

/* input[type="range"]::-webkit-slider-runnable-track {
  background: #e7c184;
  height: 0.5rem;
}

input[type="range"]::-moz-range-track {
  background: #e7c184;
  height: 0.5rem;
} */

body {
  background-color: #444fb5;
  background-size: cover;
  min-height: 100vh;
}

html {
  scroll-behavior: smooth;
}
